import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Modal, Row, Container, Col } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/auth/actions";
import { getNotifications } from "../redux/app/actions";
import { getUserBet } from "../redux/sports/actions";
import { AiOutlineClose } from "react-icons/ai";
import numberWithCommas from "../utils/numbersWithComma";
// import Index from "../views/pages/privatePages/exchangepopup";

import logo from "../assets/images/logo.webp";
import UserIcon from "../assets/images/profile.webp";
import ProfileIcon from "../assets/svg/ProfileIcon";
import BetIcon from "../assets/svg/BetIcon";
import BonusImg from "../assets/images/bonus.webp";
import refferal from "../assets/images/refferal.webp";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";

const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  const [showToggle, setShowToggle] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "homr",
    "sports",
    "betby",
    "allCasino",
    "Slots",
    "aviator",
  ];

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header aftrlgn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                </div>
                <div className="bottom_head ">
                  <Container fluid>
                    <Row>
                      <Col xs={12} className="">
                        <ul>
                          <li>
                            <a
                              href="/sports/Inplay"
                              className={activePage == "sports" ? "active" : ""}
                            >
                              <span>Exchange</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/betby"
                              className={activePage == "betby" ? "active" : ""}
                            >
                              <span>Sports</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/allCasino"
                              className={
                                activePage == "allCasino" ? "active" : ""
                              }
                            >
                              <span>All Casino</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/Slots"
                              className={activePage == "Slots" ? "active" : ""}
                            >
                              <span>Slots</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/casino/spribe/aviator"
                              className={
                                activePage == "aviator" ? "active" : ""
                              }
                            >
                              <span>Aviator</span>
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <div className="headerRight">
                  <Dropdown align="end" className="me-2">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="tel:{user?.mobileno}">
                        <b>{user?.mobileno}</b>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Balance</h6>
                        <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Item>

                      <Dropdown.Item href="#">
                        <h6>Bonus</h6>
                        <span>INR {numberWithCommas(wallet?.coins)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/gatewaylist" className="deposit">
                        Deposit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="funds me-2">
                    <ul>
                      <li className="">
                        <a href="/gatewaylist" className="deposit">
                          Deposit
                        </a>
                      </li>
                    </ul>
                  </div>
                  <Button variant="primary menu-btn" onClick={handleShow}>
                    <img
                      src={UserIcon}
                      alt="my accunt"
                      style={{ width: "32px" }}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <h6>{user?.mobileno}</h6>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">Bonus</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="primary  w-100">Withdraw</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <ProfileIcon />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <BetIcon />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <StatementIcon />
                  <span>Account Statement</span>
                </a>
              </li>

              <li onClick={() => dispatch(logoutUser())}>
                <a href="#">
                  <LogoutIcon />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  href={appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"}
                  target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>

              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
