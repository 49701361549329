import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const navigate = useNavigate();
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const { appDetails } = useSelector((state) => state.app);

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}

      <header className="header beforeheader">
        <div className="top_head">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                  <div className="bottom_head">
                    <Container fluid>
                      <Row>
                        <Col xs={12} className="">
                          <ul>
                            <li>
                              <a href="/mobile-number">
                                <span>Exchange</span>
                              </a>
                            </li>
                            <li>
                              <a href="/mobile-number">
                                <span>Sports</span>
                              </a>
                            </li>
                            <li>
                              <a href="/mobile-number">
                                <span>All Casino</span>
                              </a>
                            </li>
                            <li>
                              <a href="/Slots">
                                <span>Slots</span>
                              </a>
                            </li>
                            <li>
                              <a href="/casino/spribe/aviator">
                                <span>Aviator</span>
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  <div className="headerRight ">
                    <div className="login-signup-btn">
                      <Button
                        onClick={() => {
                          navigate("/sign-in");
                        }}
                        className=""
                      >
                        <span>Log In</span>
                      </Button>
                    </div>
                    <div className="login-signup-btn BonusAnimation">
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate("/mobile-number");
                        }}
                        className="button-primary btn_signup ms-2"
                      >
                        <span>Register</span>
                      </Button>
                      <div className="authorization__animation-item firstChild"></div>
                      <div className="authorization__animation-item secondChild"></div>
                      <div className="authorization__animation-item thirdChild"></div>
                      <div className="authorization__animation-item fourthChild"></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Header;
