import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import PlayBtn from "../../../../assets/images/play-btn.png";

import Evolution1 from "../../../../assets/images/Evolution_images/top_game/Auto-Roulette.jpg";
import Evolution2 from "../../../../assets/images/Evolution_images/top_game/Bac-Bo.jpg";
import Evolution3 from "../../../../assets/images/Evolution_images/top_game/Infinite-blackajack.jpg";
import Evolution4 from "../../../../assets/images/Evolution_images/top_game/speed-baccarat-A.jpg";
import Evolution5 from "../../../../assets/images/Evolution_images/top_game/super-sic-bo.jpg";
import Evolution6 from "../../../../assets/images/Evolution_images/roulette/American-R.jpg";
import Evolution7 from "../../../../assets/images/Evolution_images/roulette/Auto-roulette-vip.jpg";
import Evolution8 from "../../../../assets/images/Evolution_images/roulette/Double-ball-roulette.jpg";
import Evolution9 from "../../../../assets/images/Evolution_images/roulette/Dragonra-Roulette.jpg";
import Evolution10 from "../../../../assets/images/Evolution_images/roulette/Football-studio-R.jpg";
import Evolution11 from "../../../../assets/images/Evolution_images/roulette/Instanat-Roulette.jpg";
import Evolution12 from "../../../../assets/images/Evolution_images/roulette/Roulette.jpg";
import Evolution13 from "../../../../assets/images/Evolution_images/roulette/salon-prive-roulette.jpg";
import Evolution14 from "../../../../assets/images/Evolution_images/roulette/Speed-auto-roulette.jpg";
import Evolution15 from "../../../../assets/images/Evolution_images/roulette/Speed-Roulette.jpg";
import Evolution16 from "../../../../assets/images/Evolution_images/roulette/VIP-Roulette..jpg";
import Evolution17 from "../../../../assets/images/Evolution_images/evolution/cash-or-crash.jpg";
import Evolution18 from "../../../../assets/images/Evolution_images/evolution/Crazy-Time.jpg";
import Evolution19 from "../../../../assets/images/Evolution_images/evolution/dead-or-alive-saloon.jpg";
import Evolution20 from "../../../../assets/images/Evolution_images/evolution/Dream-catcher..jpg";
import Evolution22 from "../../../../assets/images/Evolution_images/evolution/Extra-chili-epic-spins.jpg";
import Evolution24 from "../../../../assets/images/Evolution_images/evolution/football-studio.jpg";
import Evolution25 from "../../../../assets/images/Evolution_images/evolution/Crazy-Time-A..jpg";
import Evolution27 from "../../../../assets/images/Evolution_images/evolution/Gonzos-treasure-Hunt.jpg";
import Evolution28 from "../../../../assets/images/Evolution_images/evolution/mega-ball.jpg";
import Evolution29 from "../../../../assets/images/Evolution_images/Poker/casino-holdem.jpg";
import Evolution30 from "../../../../assets/images/Evolution_images/Poker/hand-casino-holdem.jpg";
import Evolution31 from "../../../../assets/images/Evolution_images/Poker/side-bet-city.jpg";
import Evolution32 from "../../../../assets/images/Evolution_images/Poker/texas-holdem-bonus-pokar.jpg";
import Evolution33 from "../../../../assets/images/Evolution_images/Poker/three-card-pokar.jpg";
import Evolution34 from "../../../../assets/images/Evolution_images/Poker/ultimate-texas-holdem.jpg";
import Evolution35 from "../../../../assets/images/Evolution_images/blackjack/Blackjack-VIP-18.jpg";
import Evolution36 from "../../../../assets/images/Evolution_images/blackjack/Blackjack-Vip-Beta.jpg";
import Evolution37 from "../../../../assets/images/Evolution_images/blackjack/Calssic-speed-blackjack1.jpg";
import Evolution38 from "../../../../assets/images/Evolution_images/blackjack/Craps.jpg";
import Evolution39 from "../../../../assets/images/Evolution_images/blackjack/Speed-vip-blackjack-E.jpg";
import Evolution40 from "../../../../assets/images/Evolution_images/blackjack/power-blackjack.jpg";
import Evolution41 from "../../../../assets/images/Evolution_images/blackjack/classic-speed-blackjack-32.jpg";
import Evolution42 from "../../../../assets/images/Evolution_images/blackjack/classic-speed-blackjack-10.jpg";
import Evolution43 from "../../../../assets/images/Evolution_images/Baccart&sicbo/Bac-Bo.jpg";
import Evolution44 from "../../../../assets/images/Evolution_images/Baccart&sicbo/Baccarat-B.jpg";
import Evolution45 from "../../../../assets/images/Evolution_images/Baccart&sicbo/No-commission-Baccarat..jpg";
import Evolution46 from "../../../../assets/images/Evolution_images/Baccart&sicbo/dragon-tiger.jpg";
import Evolution47 from "../../../../assets/images/Evolution_images/Baccart&sicbo/speed-baccarat-H.jpg";

import Footer from "../../../../containers/Footer";
import { Tab, Tabs } from "react-bootstrap";
import GamesIcon from "../../../../assets/svg/GamesIcon";

const Evolution = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div className="slots-section evolution_gamess">
        <div className="games-section">
          <div className="game-heading ">
            <GamesIcon />
            <span>Evolution</span>
          </div>
          <Tabs
            defaultActiveKey="All"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="All" title="Top Games">
              <ul>
                <li>
                  <a href="/casino/evolution/CrazyTime">
                    <img src={Evolution18} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/FootballStudio">
                    <img src={Evolution24} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/Megaball">
                    <img src={Evolution28} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/CashorCrash">
                    <img src={Evolution17} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/GonzoTreasureHunt">
                    <img src={Evolution27} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/DreamCatcher">
                    <img src={Evolution20} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/MystryReels">
                    <img src={Evolution19} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </Tab>
            <Tab eventKey="Rolueltte" title="Rolueltte">
              <ul>
                {" "}
                <li>
                  <a href="/casino/evolution/AutoRoulette">
                    <img src={Evolution1} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/AmericanRoulette">
                    <img src={Evolution6} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/RouletteVip">
                    <img src={Evolution7} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/DoubleBallRouletteLive">
                    <img src={Evolution8} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/DazzleMegaways">
                    <img src={Evolution11} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/DivinieFortuneMegaways">
                    <img src={Evolution12} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/Dynamiteriches">
                    <img src={Evolution13} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FortuneHouse">
                    <img src={Evolution14} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FruitShopMegaways">
                    <img src={Evolution15} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/GoldenCryptex">
                    <img src={Evolution16} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </Tab>
            <Tab eventKey="Blackjack" title="Blackjack">
              <ul>
                <li>
                  <a href="/casino/evolution/InfiniteBlackjack">
                    <img src={Evolution3} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution35} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution36} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution37} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution38} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution39} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution40} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution41} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution42} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </Tab>
            <Tab eventKey="Poker" title="Poker">
              <ul>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution29} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution30} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution31} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution32} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution33} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution34} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </Tab>
            <Tab eventKey="Baccart" title="Baccart & Sicbo">
              <ul>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratA">
                    <img src={Evolution4} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution44} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution45} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution46} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Evolution47} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/supersicbo">
                    <img src={Evolution5} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Evolution;
