import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import Whatsapp from "../assets/images/Whatsapp.webp";
import Telegram from "../assets/images/Telegram.webp";
import Facebook from "../assets/images/Facebook.webp";
import Instagram from "../assets/images/Instagram.webp";
import SupportIcon from "../assets/images/footer/support.webp";
import SupportIcon1 from "../assets/images/footer/security.webp";
import DownloadNew from "../assets/images/icons/apk.webp";
import AviatorIcon from "../assets/images/icons/Aviator.webp";
import ExchangeIcon from "../assets/images/icons/exchange.webp";

const BeforeLoginFooter = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const isApk = localStorage.getItem("isApk") === "true";

  return (
    <footer className="footer">
      <Container>
        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactuss">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrules">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <hr className="my-3"></hr>

        <p className="velkisentence">
          Auraplay is a product of Auraplay group which operates in accordance
          with the License granted by SVG Gambling Commission under the license
          # ---- Auraplay is Powered by Betfair.com
        </p>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>

        <hr className="my-3"></hr>

        <div className="SupportSection">
          <div className="SupportSingleSection">
            <div className="SupportImgIcon">
              <img src={SupportIcon} alt="support" />
            </div>
            <h4>
              Any question? We are <a href="/contactuss">here 24/7</a>
            </h4>
            <p>
              To give you the best player experience our support is here to give
              you the answers to your questions any time of the day of the week.
              We are a dedicated and friendly team, and most important we love
              to help.
            </p>
          </div>

          <div className="SupportSingleSection">
            <div className="SupportImgIcon">
              <img src={SupportIcon1} alt="support" />
            </div>
            <h4>A secure gaming site</h4>
            <p>
              Auraplay is fully licensed and regulated. When you play with us,
              you can be sure that we are following a high standard of
              compliance and that your transactions and details are kept safe
              and secure.
            </p>
          </div>
        </div>

        <div className="social-icons">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Facebook} alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>
      </Container>

      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <img src={ExchangeIcon} alt="AviatorIcon" />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a href="/sign-in">
              <img src={AviatorIcon} alt="AviatorIcon" />
              <span>Aviator</span>
            </a>
          </li>
          <li>
            <a className="active" href="/">
              <HomeIcon />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="/sign-in">
              <GamesIcon />
              <span>All Casino</span>
            </a>
          </li>
          {!isApk && (
            <li>
              <a
                href="https://dl.dropbox.com/scl/fi/m1ndppbcstbj5pdd5jy56/Auraplay.apk?rlkey=ltanuios3paky8qq0rix8f5rl&st=iwrbwkwd&dl=0"
                download="https://dl.dropbox.com/scl/fi/m1ndppbcstbj5pdd5jy56/Auraplay.apk?rlkey=ltanuios3paky8qq0rix8f5rl&st=iwrbwkwd&dl=0"
              >
                <img src={DownloadNew} alt="ExchangeIcon" />
                <span className="apkdownload">Download APK</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
